/* eslint-disable max-len */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { Link, graphql, useStaticQuery } from 'gatsby'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { Typography, Container } from '@material-ui/core'
import { customButton } from 'utils/customComponentMaterialUI'
import { getURL } from 'utils/common'
import { COLORS } from 'config/constants/common'
import './index.scss'

export default function Profile(props) {
  const data = useStaticQuery(graphql`
    query {
      postgres {
        allEcmsTimezones {
          nodes {
            timezoneId
            timezoneName
          }
        }
      }
    }
  `)

  const { isUnlistedWebinar, listWebinars = [] } = props
  const { postgres = {} } = data || {}
  const { allEcmsTimezones = {} } = postgres || {}
  const { nodes: listTimezones = [] } = allEcmsTimezones || {}
  const { t } = useTranslation('presenter')
  const CustomButton = customButton('#333', COLORS.clSecond)

  // const listWebinars = [
  //   {
  //     endDate: '2020-10-07T12:00:00',
  //     panelId: null,
  //     sequenceNo: 4,
  //     startDate: '2020-10-07T11:15:00',
  //     timezoneId: 81,
  //     webinarId: 22,
  //     webinarName:
  //       'Business chances and standardization and regulation trends for data utilization business: Dr. Mochizuki, NEC Fellow',
  //     webinarType: 'Non-Panel',
  //     zoomLink: 'zoom.us',
  //   },
  //   {
  //     endDate: '2020-10-08T17:15:00',
  //     panelId: null,
  //     sequenceNo: 5,
  //     startDate: '2020-10-08T16:30:00',
  //     timezoneId: 71,
  //     webinarId: 85,
  //     webinarName: 'クラウドロボティクス －通信を活用したロボティクスの革新－：吉田SPR',
  //     webinarType: 'Non-Panel',
  //     zoomLink: 'zoom.us',
  //   },
  //   {
  //     endDate: '2020-10-08T10:30:00',
  //     panelId: null,
  //     sequenceNo: 5,
  //     startDate: '2020-10-08T09:45:00',
  //     timezoneId: 77,
  //     webinarId: 51,
  //     webinarName: 'Technology Vision: Dr. Akio Yamada, SVP',
  //     webinarType: 'Non-Panel',
  //   },
  //   {
  //     endDate: '2020-10-09T16:30:00',
  //     panelId: null,
  //     sequenceNo: 9,
  //     startDate: '2020-10-09T15:45:00',
  //     timezoneId: 81,
  //     webinarId: 114,
  //     webinarName: 'Post-5G/Beyond-5G：浅井Ｈ',
  //     webinarType: 'Non-Panel',
  //     zoomLink: 'zoom.us',
  //   },
  //   {
  //     endDate: '2020-10-09T17:15:00',
  //     panelId: null,
  //     sequenceNo: 9,
  //     startDate: '2020-10-09T16:30:00',
  //     timezoneId: 81,
  //     webinarId: 116,
  //     webinarName: 'SEの業務をDXする、ICTシステム設計の自動化技術 - 使い方と仕組み -：黒田PR',
  //     webinarType: 'Non-Panel',
  //   },
  // ]

  const renderAllWebinars = () => {
    const reorganizedListWebinars = []
    listWebinars.forEach((webinar) => {
      const { startDate = '' } = webinar || {}
      const formatedStartDate = moment(startDate).format('DD-MM-YYYY')
      const indexInReorganizedList = reorganizedListWebinars.findIndex((element) => {
        const { date: elementStartDate } = element
        return elementStartDate && formatedStartDate === elementStartDate
      })
      if (indexInReorganizedList > -1) {
        const { list = [] } = reorganizedListWebinars[indexInReorganizedList] || {}
        reorganizedListWebinars[indexInReorganizedList].list = [...list, webinar]
      } else {
        const newReorganizedItem = { date: formatedStartDate, list: [webinar] }
        reorganizedListWebinars.push(newReorganizedItem)
      }
    })

    const listAllRendered = []

    reorganizedListWebinars
      .sort((date1, date2) => (moment(date1.date).isAfter(moment(date2.date)) ? 1 : -1))
      .forEach((webinar) => {
        const { date: webinarDate = '', list = [] } = webinar || {}
        listAllRendered.push(renderLabelListWebinars(webinarDate))
        listAllRendered.push(renderListWebinars(list))
      })

    return listAllRendered
  }

  const renderLabelListWebinars = (date) => {
    const newDate = moment(date, 'DD-MM-YYYY').format('DD/M ddd')
    return (
      <div className="exhItem-title WebinarList-title" key={date} style={{ marginBottom: 10 }}>
        <div className="exhItem-signal" />
        <p className="exhItem-txt">{newDate}</p>
      </div>
    )
  }

  const renderListWebinars = (listWebinars) => {
    return listWebinars.map((element, index) => {
      const {
        webinarName = '',
        startDate = '',
        endDate = '',
        timezoneId = 71,
        webinarId,
        panelId,
        zoomLink,
      } = element || {}
      const renderedItem = []

      if (index > 0) {
        renderedItem.push(<hr key={'hr' + webinarId} className="exhItem-hr" />)
      }

      const formatStartTime = moment(startDate).format('HH:mm')
      const formatEndTime = moment(endDate).format('HH:mm')

      renderedItem.push(
        <div className="panelItem" key={webinarId}>
          <Typography variant="h5" className="panelItem-time">
            {`${formatStartTime} - ${formatEndTime}`}{' '}
            <span className="panelItem-time_zone">{getTimezoneName(timezoneId)}</span>
          </Typography>

          <div className="panelItem-container">
            <div className="panelItem-info">
              <Typography variant="h5" className="panelItem-title">
                {webinarName}
              </Typography>
            </div>

            <div className="panelItem-btns">
              <a
                href={zoomLink ? getURL(zoomLink) : '#'}
                target="_blank"
                rel="noreferrer"
                className="IEbuttons"
              >
                <CustomButton className="panelItem-btns_zoom">
                  <p className="link_txt">{t('common:btn_join_now_en')}</p>
                </CustomButton>
              </a>

              {isUnlistedWebinar && (
                <Link to={`/exhibitions/${panelId}`} className="IEbuttons">
                  <CustomButton className="panelItem-btns_slide">
                    <p className="link_txt">{t('lbl_panel_detail')}</p>
                  </CustomButton>
                </Link>
              )}
            </div>
          </div>
        </div>
      )
      return renderedItem
    })
  }

  const getTimezoneName = (timezoneId) => {
    if (!timezoneId || listTimezones.length === 0) return ''
    const timezone =
      listTimezones.find((element) => {
        const { timezoneId: elementTimezoneId } = element
        return timezoneId === elementTimezoneId
      }) || {}
    return timezone.timezoneName
  }

  return (
    <Container className="PanelList">
      {listWebinars.length > 0 ? (
        renderAllWebinars()
      ) : (
        <p className="PanelList-nodata">
          {`No ${isUnlistedWebinar ? 'Panel' : 'Webinar'} Presentation Scheduled`}
        </p>
      )}
    </Container>
  )
}
