import React, { useState, useEffect } from 'react'
import { graphql } from 'gatsby'
import { useTranslation } from 'react-i18next'
import { Container } from '@material-ui/core'
import SEO from 'components/Seo'
import TabPanel from 'components/Others/TabPanel/tabpanel'
import PropTypes from 'prop-types'
import AppHeading from 'components/AppHeading'
import Tabs from 'components/widgets/Tabs'
import { useAuth } from 'store/Auth'
import { isPresenter as checkPresenter } from 'utils'
import TourList from 'components/widgets/Tour/List'
import PanelList from './PanelList'
import { WEBINAR_TYPES, TRACKING, IS_PRESENTER_USE_WEBINAR_TABS } from 'config/constants/common'
import { pageView } from 'utils/tracker'
import './index.scss'

export default function PresenterPage(props) {
  const { t } = useTranslation('presenter')
  const { authData = {} } = useAuth()
  const isPresenter = checkPresenter(authData)

  const { userId } = authData || {}
  const { displayName = '[Username]' } = authData || {}
  const { data = {} } = props || {}
  const { postgres = {} } = data || {}
  const { allEcmsUsers = {} } = postgres || {}
  const { nodes: listUserWithWebinar = [] } = allEcmsUsers || {}
  const [tabActiveIndex, setTabActiveIndex] = useState(0)
  const [isFirstView, setFirstView] = useState(true)

  useEffect(() => {
    if (isPresenter) {
      if (isFirstView) {
        pageView(trackingEventSource)
        setFirstView(false)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const { ecmsEventWebinarsByHostName = {} } =
    listUserWithWebinar.find((element) => {
      const { userId: elementUserId } = element || {}
      return userId === elementUserId
    }) || {}
  const { nodes: listWebinarOfCurrentUser = [] } = ecmsEventWebinarsByHostName || {}

  const listUnlistedWebinar = listWebinarOfCurrentUser.filter((element) => {
    const { webinarType } = element || {}
    return WEBINAR_TYPES.UNLISTED === webinarType
  })

  const listOthersWebinar = listWebinarOfCurrentUser.filter((element) => {
    const { webinarType } = element || {}
    return WEBINAR_TYPES.PANEL === webinarType || WEBINAR_TYPES.NON_PANEL === webinarType
  })

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  }

  const tabs = IS_PRESENTER_USE_WEBINAR_TABS
    ? [(t('lbl_tab1'), t('lbl_tab2'), t('lbl_tab3'))]
    : [t('lbl_tab1')]
  const trackingEventSource = TRACKING.SOURCE.PRESENTATION
  const appTitle = `${displayName} ${t('lbl_page_title_en')}`

  return (
    <div id="presenter-view" className={'Layout-children'}>
      <SEO title={appTitle} />
      <AppHeading title={appTitle} />

      <div className="presenter-tabbarRoot">
        <Container>
          <Tabs
            tabs={tabs}
            tabActiveIndex={tabActiveIndex}
            handleChangeTab={(event, index) => setTabActiveIndex(index)}
            className="Webinars-tabsCustom"
          />
        </Container>
      </div>

      <div className="content">
        <div className="main" style={{ paddingBottom: '50px', paddingTop: '35px' }}>
          <Container>
            <TabPanel value={tabActiveIndex} index={0}>
              <TourList fromPresentation />
            </TabPanel>

            <TabPanel value={tabActiveIndex} index={1}>
              <PanelList isUnlistedWebinar listWebinars={listUnlistedWebinar} />
            </TabPanel>

            <TabPanel value={tabActiveIndex} index={2}>
              <PanelList listWebinars={listOthersWebinar} />
            </TabPanel>
          </Container>
        </div>
      </div>
    </div>
  )
}

export const query = graphql`
  query($eventId: Int!) {
    postgres {
      allEcmsUsers {
        nodes {
          ecmsEventWebinarsByHostName(orderBy: START_DATE_ASC, condition: { eventId: $eventId }) {
            nodes {
              webinarId
              panelId
              startDate
              endDate
              webinarName
              webinarType
              sequenceNo
              timezoneId
              zoomLink
            }
          }
          userId
        }
      }
    }
  }
`
