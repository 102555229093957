import React from 'react'

function TabPanel(props) {
    const { children, value, index, ...other } = props

    // IMPORT THIS COMMENT IF U WANNA USE TABPANEL
    //
    // import PropTypes from 'prop-types';
    // TabPanel.propTypes = {
    //     children: PropTypes.node,
    //     index: PropTypes.any.isRequired,
    //     value: PropTypes.any.isRequired,
    // };

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`wrapped-tabpanel-${index}`}
            aria-labelledby={`wrapped-tab-${index}`}
            {...other}
        >
            {value === index && (
                <div>{children}</div>
            )}
        </div>
    )
}

export default TabPanel
